<div class="footer" [ngClass]="{'off': hideFooter, 'on': menuOpen}">
    <div class="scroll">
        <ng-container *ngFor="let section of sections">
            <div class="section-nav-link" (click)="sectionNavClick(section)">
                <div class="circle">
                    <img [src]="section.nav_img" [alt]="section.title">
                </div>
    
                <p [innerHTML]="section.title"></p>
            </div>
        </ng-container>
    
        <ng-container *ngFor="let link of content">
            <a *ngIf="link.href" class="link" [href]="link.href" target="_blank" [innerHTML]="link.name"></a>
            <a href="#" *ngIf="!link.href" class="link" (click)="openModal($event, link.id)" [innerHTML]="link.name"></a>
        </ng-container>
    </div>
</div>

<div class="footer-modal" [ngClass]="{'on': showModal, 'fade': fadeInOut}">
    <div class="cover" (click)="hideModal()"></div>

    <div class="centered">
        <div class="close" (click)="hideModal()"></div>
        <div class="content">
            <h3 [innerHTML]="modalContent.name"></h3>
            <div [innerHTML]="modalContent.content"></div>
        </div>
    </div>
</div>

<div class="cover license-cover" [ngClass]="{'on': showLicenseModal, 'fade': fadeInOutLicense}" (click)="hideLicenseModal()"></div>
<div class="license-modal white-modal" [ngClass]="{'on': showLicenseModal, 'fade': fadeInOutLicense}">
    <div class="close close-flag" (click)="hideLicenseModal()">
        <div class="x"></div>
        <span>Close</span>
    </div>

    <div class="mobile-close" (click)="hideLicenseModal()"></div>

    <div class="content">
        <h3 [innerHTML]="licenseContent.name"></h3>
        <div [innerHTML]="licenseContent.content"></div>
    </div>
</div>

<div class="menu-button" [ngClass]="{'on': menuOpen}" (click)="toggleMenu()">
    <div>
        <div class="icon"></div>
        <p>Menu</p>
    </div>
</div>
