import { Component, OnInit, Output, EventEmitter, ComponentFactoryResolver, Input, OnChanges, ViewChild, ElementRef, AfterViewInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as data from '../data.json';

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.scss']
})

export class CityComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() preloadVideoDone: boolean;
    @Input() footerSectionInput: any;

    @Output() sectionActiveOutput = new EventEmitter<any>();

    @ViewChild('targetVideo') targetVideoEl: ElementRef;

    public sections = data.sections;
    public sectionOpen = false;
    public nextSection: any = undefined;
    public activeSection: any = undefined;
    public activeHotspot: any = undefined;
    public showSidebar = false;
    public sectionVideo = '../../assets/restaurant.mp4';
    public sectionVideoPlaying = false;
    public hideSectionLinks = false;
    public sectionTransition = false;
    public showPlate = false;
    public hideNav = false;
    public forward = true;
    public minAspect = false;
    public animateScrollable = true;
    public oldScroll = 0;
    public showSectionImage = false;
    public activeIndex = 0;
    public animateSectionImage = false;
    public mobileMenu = false;
    public hideSelectModal = false;
    public showOutline = false;
    showCity: boolean;
    public showPrompt = false;
    public mobile = false;
    public mobileStyle = '';

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.checkForMobile();
        this.onResize();
        setTimeout(() => {
            this.showCity = true;
        }, 500);
    }

    public checkForMobile(): void {
        if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
            this.mobile = true;
            document.querySelector('body').className += ' mobile';
            window.addEventListener('orientationchange', this.checkHeight.bind(this));
        }
    }

    public onResize(): void {
        this.mobileMenu = window.innerWidth <= 1024;

        if (Math.abs(Math.round((100 - (window.innerWidth / window.innerHeight) * 100) * 100) / 100) >= 77.78) {
            this.minAspect = true;
        } else {
            this.minAspect = false;
        }

        this.checkHeight();
    }

    public checkHeight(): void {
        if (this.mobile) {
            const check = setInterval(this.setHeight.bind(this), 10);
            setTimeout(() => {
                clearInterval(check);
            }, 750);
        }
    }

    public setHeight(): void {
        document.querySelector('body')['style'].setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    ngAfterViewInit(): void {
        const videoEl = this.targetVideoEl.nativeElement as HTMLVideoElement;
        videoEl.addEventListener('ended', this.endedHandler.bind(this));
        videoEl.addEventListener('play', this.playHandler.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.preloadVideoDone?.currentValue && this.mobileMenu) {
            setTimeout(() => {
                this.hideSelectModal = true;
            }, data.select_modal_timeout);
        }

        if (changes.preloadVideoDone?.currentValue) {
            this.animateScrollable = false;
            this.showOutline = true;
            this.showPrompt = true;

            setTimeout(() => {
                this.showPrompt = false;
            }, 5000);
        }

        if (changes.footerSectionInput?.currentValue) {
            const playVideo = (this.activeSection ? false : true);
            this.onSectionClick(changes.footerSectionInput.currentValue, true, playVideo);
        }
    }

    public onSectionClick(section, fromNav = false, playVideo = false): void {
        if (fromNav) {
            if (this.activeSection?.id === section.id) {
                return;
            }
        } else if (this.sectionOpen) {
            return;
        }

        this.activeIndex = this.sections.map(e => e.id).indexOf(section.id);
        this.hideSelectModal = true;
        this.showPrompt = false;

        if (playVideo) {
            this.showOutline = false;
            this.hideSectionLinks = true;
            this.sectionTransition = true;
            this.sectionActiveOutput.emit(true); // hide footer
            this.sectionVideo = `../../assets/${section.id}.mp4`; // TODO update video urls

            setTimeout(() => {
                const videoEl = this.targetVideoEl.nativeElement as HTMLVideoElement;
                this.sectionVideoPlaying = true; // video = display block
                // this.activeSection = section;
                this.nextSection = section;

                setTimeout(() => {
                    videoEl.currentTime = 0;
                    videoEl.play();
                }, 0);
            }, 350);
        } else {
            this.closeSidebar();
            this.sectionTransition = true;
            this.sectionOpen = true;
            this.activeSection = section;
            this.sectionActiveOutput.emit(true); // hide footer

            setTimeout(() => {
                this.sectionTransition = false;
            }, 500);
        }
    }

    public endedHandler(): void {
        if (this.forward) {
            this.showSectionImage = true;
            this.sectionVideoPlaying = false;
            this.showPlate = true;

            setTimeout(() => {
                // this.sectionVideoPlaying = false;
                this.sectionOpen = true;
                this.sectionTransition = false;
                this.animateSectionImage = true;
            }, 250);
        } else {
            this.activeSection = undefined;

            setTimeout(() => {
                this.forward = true;
                this.showSectionImage = false;
                this.hideSectionLinks = false;
                this.sectionVideoPlaying = false;
                this.sectionActiveOutput.emit(false);  // show footer
                this.showOutline = true;
            }, 250);
        }
    }

    public playHandler(): void {
        if (this.forward) {
            setTimeout(() => {
                this.activeSection = this.nextSection;
            }, 100);
        } else {
            this.sectionOpen = false;
            this.hideNav = false;
        }
    }

    public exitSectionClick(): void {
        this.sectionTransition = true;
        this.showPlate = false;
        this.hideNav = true;
        this.forward = false;
        this.sectionVideo = `../../assets/${this.activeSection.id}_reverse.mp4`;
        this.animateSectionImage = false;
        const videoEl = this.targetVideoEl.nativeElement as HTMLVideoElement;

        setTimeout(() => {
            this.sectionVideoPlaying = true;
            videoEl.currentTime = 0;
            videoEl.play();
        }, 350);
    }

    public handleTransform(scaleUp): void {
        if (this.mobileMenu) {
            return;
        }

        if (scaleUp) {
            const bumper = (this.minAspect ? 10 : 5);
            const x = this.activeHotspot.x - 50 + bumper;
            const y = this.activeHotspot.y;
            const transform = `scale(2) translateY(${-y}%) translateX(${-x}%)`;

            if (!this.minAspect) {
                this.oldScroll = document.getElementsByClassName('city')[0].scrollLeft;
                document.getElementsByClassName('city')[0].scroll({top: 0, left: 10000, behavior: 'smooth'});
            }
            if (document.getElementById('scroll')) {
                this.setPrefix(document.getElementById('scroll'), 'transform', transform);
                document.getElementById('scroll').style.top = '100%';
            }
        } else {
            const transform = 'scale(1) translateX(0%)';

            if (!this.minAspect) {
                document.getElementsByClassName('city')[0].scroll({top: 0, left: this.oldScroll, behavior: 'smooth'});
            }
            if (document.getElementById('scroll')) {
                this.setPrefix(document.getElementById('scroll'), 'transform', transform);
                document.getElementById('scroll').style.top = '0%';
            }
        }
    }

    private setPrefix(element, property, value): void {
        element.style[property] = value;
        element.style["-webkit-" + property] = value;
        element.style["-moz-" + property] = value;
        element.style["-ms-" + property] = value;
        element.style["-o-" + property] = value;
    }

    public onHotspotClick(hotspot): void {
        this.showSidebar = true;
        this.animateScrollable = true;
        this.activeHotspot = hotspot;
        this.handleTransform(true);
    }

    public closeSidebar(): void {
        this.showSidebar = false;
        this.handleTransform(false);

        setTimeout(() => {
            this.activeHotspot = undefined;
            this.animateScrollable = false;
        }, 350);
    }

    public tabClick(tab): void {
        if (!tab.active) {
            this.activeHotspot.tabs.forEach(el => {
                el.active = false;
            });

            tab.active = true;
        }
    }

    public handleSvg(svg): any {
        return this.sanitizer.bypassSecurityTrustHtml(svg);
    }

    public sectionNavClick(target, arrows = false): void {
        let nextSection;
        let index;

        if (arrows) {
            index = this.sections.map(e => e.id).indexOf(this.activeSection.id);

            if (target === 'next') {
                index++;
                if (index === this.sections.length) {
                    index = 0;
                }

                nextSection = this.sections[index];
            } else if (target === 'prev') {
                index--;
                if (index < 0) {
                    index = this.sections.length - 1;
                }

                nextSection = this.sections[index];
            }
        } else {
            nextSection = target;
            index = this.sections.map(e => e.id).indexOf(target.id);
        }

        this.activeIndex = index;
        this.onSectionClick(nextSection, true);
    }
}
