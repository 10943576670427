<app-preloader *ngIf="!preloaded" (pageLoadedOutput)="handlePreloaded($event)"></app-preloader>
<app-city
    [ngClass]="{'on': preloadVideoPlaying}"
    [footerSectionInput]="footerSectionClicked"
    [preloadVideoDone]="preloaded"
    (sectionActiveOutput)="handleSectionActiveOutput($event)"
></app-city>
<app-footer
    [ngClass]="{'on': preloadVideoPlaying}"
    [sectionActiveInput]="sectionActive"
    (sectionClickOutput)="handleFooterSectionClick($event)"
></app-footer>

<div class="logo" [ngClass]="{'intro-logo': showIntro, 'off': hideLogo}">
    <img src="../../assets/logo.svg" alt="Comcast Business">
</div>

<div class="portrait-message">
    <div class="cover"></div>

    <div class="centered">
        <div class="content">
            <img src="../../assets/loading-logo.svg" alt="Comcast Business">
            <h3>Please rotate your device to portrait mode or view on a larger device.</h3>
            <p>We tried our best to make this work on all screen sizes, but this didn't make the cut. </p>
        </div>
    </div>
</div>
