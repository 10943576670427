import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-houdini-side-scroll',
  templateUrl: './houdini-side-scroll.component.html',
  styleUrls: ['./houdini-side-scroll.component.scss']
})
export class HoudiniSideScrollComponent implements OnInit {
  isUserInteracting: boolean;
  onMouseDownMouseX: any;
  onMouseDownScrollTarget: any;
  scrollTarget = 0;
  scrollAmount = 0;
  scrollStart: any;
  hasMoved = false;
  animate = false;
  wasScrollable = true;
  mobile = false;

  @Input() isScrollable: boolean;

  @ViewChild('scroll') scrollRef: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    window.addEventListener('wheel', event => this.onScroll(event));

    window.addEventListener('mousedown', this.onPointerStart.bind(this), false);
    window.addEventListener('mousemove', this.onPointerMove.bind(this), false);
    window.addEventListener('mouseup', this.onPointerUp.bind(this), false);

    window.addEventListener('touchstart', this.onPointerStart.bind(this), false);
    window.addEventListener('touchmove', this.onPointerMove.bind(this), false);
    window.addEventListener('touchend', this.onPointerUp.bind(this), false);

    const max = document.querySelector('.scrollable').getBoundingClientRect().width - window.innerWidth
    this.setScrollTarget(max / 2);
    this.startScroll();

    this.checkForMobile();
  }

  ngOnChanges(): void {
    if (!this.isScrollable && window.innerWidth / window.innerHeight < 16 / 9) {
      const max = document.querySelector('.scrollable').getBoundingClientRect().width - window.innerWidth
      this.scrollTarget = max / 2;
      setTimeout(() => {
        this.renderer.setStyle(this.scrollRef.nativeElement, 'transform', `translateX(-${this.scrollTarget}px)`);
      }, 50);
    }
  }

  public checkForMobile(): void {
    if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    }
  }

  public onResize(): void {
    this.setScrollTarget(this.scrollTarget);
    this.startScroll();
  }

  public onPointerStart(event): void {
    this.isUserInteracting = true;
    const clientX = event.clientX || event.touches[0].clientX;
    this.onMouseDownMouseX = clientX;
    this.onMouseDownScrollTarget = this.scrollTarget;
  }

  public onPointerMove(event): void {
    if (this.isUserInteracting === true) {
        const lastScrollTarget = this.scrollTarget;
        const clientX = event.clientX || event.touches[0].clientX;
        this.animate = false;
        this.setScrollTarget((this.onMouseDownMouseX - clientX) + this.onMouseDownScrollTarget);
        this.scrollAmount = this.scrollTarget - lastScrollTarget;
        this.startScroll();
    }
  }

  public onPointerUp(event): void {
    this.isUserInteracting = false;
    this.scrollAmount = 0;
  }

  public onScroll(event): void{
    this.isUserInteracting = true;
    this.scrollAmount = event.deltaY;
    this.animate = false;
    this.setScrollTarget(this.scrollTarget + this.scrollAmount);
    this.startScroll();
    setTimeout(() => {
        this.isUserInteracting = false;
        this.scrollAmount = 0;
    }, 1);
  }

  setScrollTarget(target): void {
    if (!this.isScrollable) {
      return;
    }
    this.scrollStart = this.scrollTarget;
    const max = document.querySelector('.scrollable').getBoundingClientRect().width - window.innerWidth
    const min = 0;

    this.scrollTarget = Math.min(Math.max(target, min), max);
  }

  startScroll(): void {
    if (!this.isScrollable) {
      return;
    }
    this.hasMoved = true;
    setTimeout(() => {
        this.renderer.setStyle(this.scrollRef.nativeElement, 'transform', `translateX(${-this.scrollTarget}px)`);
    }, 50);
  }
}
