import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as data from '../data.json';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnChanges {
    @Input() sectionActiveInput: boolean;
    
    @Output() sectionClickOutput = new EventEmitter<any>();

    public sections = data.sections;
    public showModal = false;
    public fadeInOut = false;
    public showLicenseModal = false;
    public fadeInOutLicense = false;
    public content = data.footer;
    public licenseContent: any;
    public modalContent = this.content[0];
    public hideFooter = false;
    public menuOpen = false;

    constructor() { }

    ngOnInit(): void {
        this.licenseContent = this.content.find(x => x.id === 'licenses');
    }

    ngOnChanges(): void {
        this.hideFooter = this.sectionActiveInput;
    }

    public openModal(event, type): void {
        event.preventDefault();
        
        if (type === 'licenses') {
            if (this.showLicenseModal) {
                this.hideLicenseModal();
            } else {
                this.showLicenseModal = true;
                setTimeout(() => {
                    this.fadeInOutLicense = true;
                }, 350);
            }
        } else {
            const currentModal = this.content.find(x => x.id === type);
            this.modalContent = currentModal;
            this.showModal = true;
            this.fadeInOut = true;
        }
    }

    public hideModal(): void {
        this.fadeInOut = false;
        setTimeout(() => {
            this.showModal = false;
        }, 350);
    }

    public hideLicenseModal(): void {
        this.fadeInOutLicense = false;
        let timeout = (window.innerWidth > 768 ? 350 : 0);
        setTimeout(() => {
            this.showLicenseModal = false;
        }, timeout);
    }

    public toggleMenu(): void {
        this.menuOpen = !this.menuOpen;
    }

    public sectionNavClick(section): void {
        this.menuOpen = false;

        setTimeout(() => {
            this.sectionClickOutput.emit(section);
        }, 350);
    }
}
