<div class="city" (window:resize)="onResize()" (window:orientationchange)="checkHeight()" [ngClass]="{'mobile': mobile}" [ngStyle]="mobileStyle">
    <div class="select-business white-modal" [ngClass]="{'on': preloadVideoDone && !hideSelectModal, 'off': hideSelectModal}">
        <div class="content">
            <h3>Select a Business</h3>
            <p>Learn about Comcast Business Solutions.</p>
        </div>
    </div>

    <div class="back-to-city box-link" (click)="exitSectionClick()" [ngClass]="{'on': sectionOpen, 'off': showSidebar || hideNav}">Back to City</div>

    <div class="section-nav" [ngClass]="{'on': sectionOpen, 'off': showSidebar || hideNav}">
        <div class="nav-arrow prev" (click)="sectionNavClick('prev', true)"><div class="icon"></div></div>

        <ng-container *ngFor="let section of sections">
            <div class="section-nav-link" (click)="sectionNavClick(section)" [ngClass]="{'active': activeSection?.id === section.id}">
                <div class="circle">
                    <img [src]="section.nav_img" [alt]="section.title">
                </div>

                <p [innerHTML]="section.title"></p>
            </div>
        </ng-container>

        <div class="nav-arrow next" (click)="sectionNavClick('next', true)"><div class="icon"></div></div>
    </div>

    <div class="sidebar" [ngClass]="{'on' : showSidebar}">
        <div class="close-container">
            <div class="close close-flag" (click)="closeSidebar()">
                <div class="x"></div>
                <span>Close</span>
            </div>
        </div>

        <div class="content">
            <div class="header">
                <div class="svg" [innerHTML]="handleSvg(activeHotspot?.icon)"></div>
                <h2 [innerHTML]="activeHotspot?.title"></h2>
                <h3 [innerHTML]="activeHotspot?.subtitle"></h3>
            </div>

            <div class="tab-link-container">
                <div *ngFor="let tab of activeHotspot?.tabs" class="tab-link" (click)="tabClick(tab)" [ngClass]="{'active': tab.active}" [innerHTML]="tab.tab_title"></div>
            </div>

            <div class="tab-content-container">
                <div class="tab-content" *ngFor="let tab of activeHotspot?.tabs" [ngClass]="{'on': tab.active}">
                    <h5 *ngIf="tab.copy_title" [innerHTML]="tab.copy_title"></h5>
                    <ul>
                        <li *ngFor="let bullet of tab.bullets" [innerHTML]="bullet"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="full-width" [ngClass]="{'mobile': mobile}">
        <app-houdini-side-scroll [isScrollable]="!animateScrollable" [className]="mobile">
            <div class="shadow top" [ngClass]="{'on': hideSectionLinks}"></div>
            <div class="shadow bottom" [ngClass]="{'on': hideSectionLinks}"></div>

            <video #targetVideo muted playsinline id="interior-zoom-video" [ngClass]="{'on': sectionVideoPlaying}" class="interior-zoom-video" [src]="sectionVideo"></video>

            <div class="city-img-container">
                <img src="../../assets/city.jpg" alt="Comcast Business City Image" class="city-img" [ngClass]="{'off': !showCity}">
                <img src="../../assets/city_outline.png" alt="" class="city-img outline" [ngClass]="{'on': showOutline}">

                <ng-container *ngFor="let image of sections; let i = index">
                    <img
                        class="section-image main"
                        [src]="'../../assets/' + image.id + '.jpg'"
                        [ngClass]="{'on': activeSection?.id === image.id, 'show': showSectionImage, 'animate': animateSectionImage, 'mobile': mobile}"
                    >
                    <img
                        class="section-image plate"
                        [src]="'../../assets/' + image.id + '_outline.png'"
                        [ngClass]="{'on': activeSection?.id === image.id, 'show': showPlate, 'animate': animateSectionImage, 'mobile': mobile}"
                    >
                </ng-container>
            </div>

            <div class="links-container" [ngClass]="{'off': sectionOpen}">
                <div
                    *ngFor="let section of sections"
                    [style.left]="section.x + '%'"
                    [style.top]="section.y + '%'"
                    class="section-link"
                    [ngClass]="[section.link_text_position, preloadVideoDone ? 'on' : '', hideSectionLinks ? 'off' : '']"
                    (click)="onSectionClick(section, false, true)">
                    <span>{{section.title}}</span>
                </div>
            </div>

            <ng-container *ngFor="let section of sections">
                <div class="hotspot-container" [ngClass]="{'on': activeSection?.id === section.id}">
                    <div
                        *ngFor="let hotspot of section.hotspots"
                        [style.left]="hotspot.x + '%'"
                        [style.top]="hotspot.y + '%'"
                        class="hotspot"
                        [ngClass]="{'on': activeSection?.id === section.id, 'off': sectionTransition, 'smaller': showSidebar}"
                        (click)="onHotspotClick(hotspot)">
                        <div class="svg" [innerHTML]="handleSvg(hotspot.icon)"></div>
                        <div class="arrow" [ngClass]="hotspot.arrow_position"></div>
                    </div>
                </div>
            </ng-container>

            <div class="controls-prompt" [ngClass]="{'on' : showPrompt}">
                <img class="controls-prompt-icon" src="../../assets/handPrompt.png">
                <p>Drag the view left and right to explore.</p>
            </div>
        </app-houdini-side-scroll>
    </div>
</div>
