<div class="preloader" (window:resize)="checkHeight()" (window:orientationchange)="checkHeight()" [ngClass]="{'off': hidePreloader, 'mobile': mobile}" [ngStyle]="mobileStyle">
    <div class="video-cover" [ngClass]="{'off': hideElements}"></div>
    <div class="video-container">
        <video playsinline id="intro-video" src="../../assets/preloader.mp4" [ngClass]="{'on': videoLoaded}"></video>
    </div>

    <div class="explore box-link" (click)="exploreCityClicked()" [ngClass]="{'on': hideElements && !videoPlaying}">Explore The City</div>

    <div class="welcome-modal white-modal" [ngClass]="{'on': hideElements && !videoPlaying}">
        <div class="content">
            <h3><span>Welcome to the</span> Comcast Business Solutions Demo</h3>
            <p>See how our solutions power businesses of all sizes.</p>
        </div>
    </div>

    <div class="inner" [ngClass]="{'off': hideIntro}">
        <img [ngClass]="{'off': hideElements}" src="../../assets/loading-logo.svg" alt="Comcast Business" class="logo">
        <div [ngClass]="{'off': hideElements}" class="progress-container">
            <div class="progress">
                <p id="percent" [style.width.%]="loadedPercentage">{{loadedPercentage + '%'}}</p>
            </div>
        </div>
    </div>
</div>
