import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    constructor() { }

    public preloaded = false;
    public preloadVideoPlaying = false;
    public sectionActive = false;
    public hideLogo = true;
    public showIntro = true;
    public footerSectionClicked: any = undefined;

    ngOnInit(): void {
    }

    public handlePreloaded(isLoaded): void {
        switch (isLoaded) {
            case 'hide-others':
                this.hideLogo = false;
            break;

            case 'video':
                this.preloadVideoPlaying = true;
                this.showIntro = false;
            break;
            
            case 'done':
                this.preloaded = true;
            break;
        }
    }

    public handleSectionActiveOutput(sectionActive): void {
        this.sectionActive = sectionActive;
    }

    public handleFooterSectionClick(section): void {
        this.footerSectionClicked = section;
    }
}
