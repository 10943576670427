import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as createjs from 'createjs-module';
(<any>window).createjs = createjs;
import * as asset_list from './asset_list.json';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
    public loadedPercentage = 0;
    public hideElements = false;
    public hidePreloader = false;
    public introVideo: HTMLVideoElement;
    public videoPlaying = false;
    public assetList = asset_list.list;
    public videoLoaded = false;
    public hideIntro = false;
    public CDN_VIDEO_URL = 'https://cdn.houdini.studio/storage/comcast/web/videos/';
    public debug = false;
    public mobile = false;
    public mobileStyle = '';

    @Output() pageLoadedOutput = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
        this.checkForMobile();
        this.debug = new URLSearchParams(window.location.search).has('debug');
        this.introVideo = document.getElementById('intro-video') as HTMLVideoElement;
        this.introVideo.load();
        this.introVideo.addEventListener('ended', this.onVideoEnded.bind(this));
        this.introVideo.onloadeddata = () => {
            this.videoLoaded = true;
            this.handleAssetList();
        };
    }

    public checkForMobile(): void {
        if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
            this.mobile = true;
            window.addEventListener('orientationchange', this.checkHeight.bind(this));
        }
    }

    public checkHeight(): void {
        if (this.mobile) {
            const check = setInterval(this.setHeight.bind(this), 10);
            setTimeout(() => {
                clearInterval(check);
            }, 750);
        }
    }

    public setHeight(): void {
        this.mobileStyle = '--vh', `${window.innerHeight * 0.01}px`;
    }

    public handleAssetList(): void {
        const newList = [];

        for (const item of this.assetList) {
            newList.push((item.cdn ? this.CDN_VIDEO_URL : '../../assets/') + item.filename);
        }

        this.preload(newList);
    }

    public preload(assetList): void {
        const loader = new createjs.LoadQueue();

        loader.on('progress', (event) => {
            this.loadedPercentage = Math.round(event['progress'] * 100);
        });

        // loader.on('fileload', (event) => {
        //     console.log(event);
        // });

        loader.on('complete', () => {
            setTimeout(() => {
                this.hideElements = true;
                this.pageLoadedOutput.emit('hide-others');

                setTimeout(() => {
                    this.hideIntro = true;
                }, 250);
            }, 250);
        });


        if (this.debug) { assetList = ['../../assets/city.jpg']; }
        loader.loadManifest(assetList);
    }

    public exploreCityClicked(): void {
        this.videoPlaying = true;
        this.introVideo.play();
        this.pageLoadedOutput.emit('video');
    }

    public onVideoEnded(): void {
        this.hidePreloader = true;

        setTimeout(() => {
            this.pageLoadedOutput.emit('done');
        }, 350);
    }
}
